var BASE_URL;
var MOBILE_URL;
var WEB_URL;
var tokendata = new URLSearchParams(
    window.location.search
        .substring(1)
        .substring(window.location.search.substring(1).indexOf("?"))
).get("token")
var piddata = new URLSearchParams(
    window.location.search
        .substring(1)
        .substring(window.location.search.substring(1).indexOf("?"))
).get("p_id")
if (piddata == "7a09ca9802cbe1556d3e") {
    BASE_URL = 'https://ri-backend-pre-prod.flyyx.in';
    WEB_URL = "https://www.pharmarack.com/Retailers/OrderCart/Create?OrderViaProductSearch=1"
    MOBILE_URL = "https://prretailer.page.link/qLo3"
} else {
    BASE_URL = 'https://ri-backend-uat.flyyx.in';
    // WEB_URL = "http://tesla.pharmarack.com/Retailers/OrderCart/Create?OrderViaProductSearch=1"
    // MOBILE_URL = "https://pharmarack.page.link/FDgu"
    WEB_URL = "https://www.ragabh.com/Retailers/OrderCart/Create?OrderViaProductSearch=1"
    MOBILE_URL = "https://pharmarack.page.link/FDgu"
}

export { BASE_URL, MOBILE_URL, WEB_URL }
